@import '../styles/base';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 0;
  border-radius: 8px;
  color: #333;
  transform: translateZ(0);

  h2 {
    font: bold 32px/2 $font-family;
    margin-bottom: 32px;
  }

  p {
    margin: 0;
  }

  > div {
    width: 100%;
    max-width: 420px;
  }

  a {
    color: $color-primary;
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }

  .field {
    margin-bottom: 16px;
  }

  .radios {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  label {
    display: block;
    margin-bottom: 8px;
  }

  input[type='text'] {
    width: 100%;
  }

  .note {
    margin-top: 4px;
    font-size: 11px;
    color: #888;
  }

  .preview {
    margin: 0 auto 24px;
    max-width: 420px;
    width: auto;
    white-space: pre-wrap;
    word-break: break-all;
    font-family: monospace;
    line-height: 1.4;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    div {
      display: block;
      height: auto;
    }

    a {
      display: flex;
      margin: 12px;
      border-radius: 6px;
      padding: 16px 24px;
      font-size: 18px;
      color: white;
      background-color: $color-primary;
      text-decoration: none;
    }
  }
}
