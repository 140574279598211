@import '../styles/base';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-wrapper {
  .header {
    height: 128px;
    background: $color-black-berry;
    max-width: 1440px;
    margin: 0 auto;
  }
}

.main-wrapper {
  flex: 1;

  .main {
    max-width: 1440px + 32px * 2;
    margin: 0 auto;
    padding: 0 32px;
  }
}

@media only screen and (max-width: 489px) {
  .main-wrapper {
    .main {
      max-width: 1440px;
      padding: 0;
    }
  }
}
