@import '../styles/base';

.header {
  height: 128px + 32px;
  background: $color-black-berry;

  .options {
    display: flex;
    justify-content: center;

    .option {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;

      &:not(:last-child) {
        margin-right: 32px;
      }

      .switch {
        display: flex;
        justify-content: center;
        align-items: center;

        .trigger {
          margin: 0 8px;
        }

        > .label {
          color: #f1f3ff;

          &:first-child {
            margin-left: 24px;
          }

          &.active {
            color: #fff;
          }
        }
      }
    }
  }

  header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-column-gap: 0px;
    align-items: start;
    padding: 20px 30px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    > * {
      white-space: nowrap;
    }

    .logo {
      padding-right: 8px;
      justify-self: start;
    }

    .rwd-control {
      justify-self: center;
      border: 1px solid $color-black-berry-light;
      border-radius: 4px;
      padding: 6px 0;

      button {
        padding: 0 8px;
        opacity: 0.5;
        cursor: pointer;

        &:first-child {
          border-right: 1px solid #3a3262;
        }

        &:hover,
        &.active {
          opacity: 1;
        }
      }
    }

    .link-to-doc {
      justify-self: end;
      font: 600 14px/32px $font-family;
      padding: 0 24px;
      border: 1px solid $color-black-berry-light;
      border-radius: 6px;
      background: #231c45;

      &:hover {
        background: #3a3262;
        border: 1px solid #fff;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header {
    header {
      .rwd-control {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 376px) and (max-width: 767px) {
  .header {
    header {
      .rwd-control {
        justify-self: end;
      }
    }
  }
}

@media only screen and (max-width: 489px) {
  .header {
    height: 168px + 32px;

    .options {
      justify-content: flex-start;
      padding: 0 30px;
    }

    header {
      .link-to-doc {
        margin-top: 8px;
        justify-self: start;
      }
    }
  }
}
