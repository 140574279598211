@import './styles/base';

.content {
  width: 100%;
  max-width: 1200px;
  margin: -33px auto 0;
  box-shadow: 0px 4px 16px rgba(68, 86, 221, 0.18);
  border-radius: 8px;
  overflow: hidden;
  height: 758px;
  max-height: calc(100vh - 95px * 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  will-change: border-radius, max-width;
  transition: border-radius 0.7s ease, max-width 0.7s ease;
  transform: translateZ(0);

  .address-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    flex: 0 0 33px;
    height: 33px;
    width: 100%;
    border-bottom: 1px solid #dfe1e5;
    background-color: #fff;
    position: relative;

    + div {
      flex: 1;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

@include respond-to(mobile) {
  .content {
    max-height: calc(100vh - 135px);
    margin-bottom: 8px;
  }
}
