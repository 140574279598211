@import './styles/base';
@import './styles/reset';

$_input-height: 40px;

html,
body,
#root {
  height: 100%;
  background: $color-background-gray;
  // color: #fff;
  // font-size: 16px;
}

body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

img {
  object-fit: contain;
  display: block;
}

a {
  text-decoration: none;
  color: #fff;
}

::placeholder {
  color: #c7c8cc;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(#999, 0.5);
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: transparent;
}

@include respond-to(mobile) {
  #root {
    overflow: auto;
  }
}
