@import './styles/base';

:global(#root) {
  > div {
    height: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &.center {
    justify-content: center;
    align-items: center;
  }

  .brand-wrapper {
    max-height: calc(100vh - 116px);
    display: flex;
    flex: 1;
    overflow: hidden;
    box-shadow: 0 0 16px #ccc;

    .left {
      width: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.ikea {
    color: #111;

    .brand-wrapper {
      .right {
        background-color: rgb(252, 141, 104);
      }
    }
  }

  &.uniqlo {
    color: #111;

    .brand-wrapper {
      .right {
        background-color: #f4f4f4;
      }
    }
  }

  &.plst {
    .brand-wrapper {
      .right {
        background-color: #ab7f62;
      }
    }
  }

  &.default {
    .brand-wrapper {
      .right {
        background-color: #231c45;
      }
    }
  }

  .logo {
    margin: 40px auto;
    height: 36px;
  }
}

.qr {
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
  margin: 32px;
  box-sizing: content-box;
}

@include respond-to(mobile) {
  .wrapper {
    &.ikea {
      .brand-wrapper {
        flex-direction: column;

        .right {
          order: 1;
        }

        .left {
          order: 2;
          width: 100%;
          flex: 1;
        }
      }
    }
  }
}
